<template>
  <div>
    <div class="registration">
      <div class="registration-top">
        <div class="registration-logo">
          <a href="/"><img src="../assets/images/logo.png" alt="车学堂"/></a>
        </div>
        <!-- <div class="go-login"><a href="#">已有账号点击登录</a></div>-->
      </div>
      <el-steps
        :active="active"
        finish-status="success"
        align-center
        class="step-box"
      >
        <el-step title="快速注册"></el-step>
        <el-step title="付费"></el-step>
        <el-step title="注册完成"></el-step>
      </el-steps>
      <div class="register-step">
        <!-- <el-tabs v-model="activeName" @tab-click="handleClick">-->
        <el-tabs v-model="activeName">
          <el-tab-pane name="third">
            <div class="accomplish-box">
              <span class="icon-right-coarse"></span>
              <h1>支付{{ msg }}</h1>
              <p>感谢你对本公司的产品以及服务的不懈支持</p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { selectorder } from '../api/order'

const steps = ['first', 'second', 'third']
export default {
  data() {
    return {
      active: 2,
      msg: '支付成功',
      out_trade_no: ''
    }
  },
  created() {
    this.out_trade_no = this.$route.query.out_trade_no
  },
  mounted() {},
  methods: {
    //查询订单
    selOrder() {
      let params = {
        out_trade_no: this.out_trade_no,
        platid: 2
      }
      selectorder(params).then(res => {
        if (res.code == 0) {
        } else {
          this.msg = '支付失败'
        }
      })
    }
  },
  computed: {
    activeName() {
      return steps[this.active]
    }
  }
}
</script>
